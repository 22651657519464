"use client";
import dynamic from "next/dynamic";
import { useState, useEffect } from "react";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { fetchElasticData } from "@/app/lib/elastic/fetchElastic";
import style from "@/app/styles/Molecules/CardLayoutVerticalList.module.scss";
import Skeleton from "@/app/components/molecules/Skeletons/Skeletons";
const ButtonSeeMore = dynamic(
	() => import("@/app/components/atoms/Buttons/Button/Button")
);
const CardCompact = dynamic(
	() => import("@/app/components/molecules/cards/CardCompact/CardCompact")
);

declare const window: WindowWithDataLayer;

type Props = {
	loadMoreLabel?: string;
	isVariantStoryPage?: boolean;
	isTerms: boolean;
	isVariantBulletin?: boolean;
	storyPageTitle?: string;
	type?: string;
	position?: any;
	cardsData?: any;
	total?: number;
	terms?: [string] | any;
	pagePath: string;
};

export default function CardLayoutVerticalList({
	isVariantStoryPage = false,
	storyPageTitle = undefined,
	loadMoreLabel = "Ver más",
	isVariantBulletin = false,
	type = "",
	position = 1,
	total = 1,
	cardsData,
	pagePath,
	terms,
	isTerms,
}: Props) {
	const [showAll, setShowAll] = useState(false);
	const [data, setData] = useState<any>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const { data, error } = await fetchElasticData({
				path: "related",
				value: terms,
				contentType: "storypage",
				pagePath,
				isTerms,
			});
			if (data) {
				setData(data);
			} else {
				console.error("Error fetching data:", error);
			}
			setLoading(false);
		};
		fetchData();
	}, [terms, pagePath, isTerms]);

	const cardsArray = cardsData || data?.data;
	const visibleCards = showAll ? cardsArray : cardsArray?.slice(0, 4);

	const blockType = type?.replace("block_content--", "")?.split("_")?.join(" ");
	const blockDataLayer = {
		event: "select_content",
		contenidosBloque: `${cardsArray?.length + 1} contenidos`,
		content_type: !isVariantStoryPage ? "Button" : "Button>Relacionados",
		countWidget: total,
		seccion: pagePath,
		story_principal: !isVariantStoryPage ? storyPageTitle : undefined,
		content_name: !isVariantStoryPage ? "Cargar más noticias" : "Cargar más",
		eventAction: "click",
		posicionBloque: position + 1 || 0,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
	};

	return (
		<>
			<div className={style["card__wrapper"]}>
				{loading ? (
					<div>
						<Skeleton isLoading={true} quantity={4} variant="vertical" />
						<Skeleton isLoading={true} quantity={1} variant="button" />
					</div>
				) : (
					visibleCards?.map((cardData: any, index: number) => {
						if (pagePath !== cardData?.field_path) {
							return (
								<div key={`card-${index}`}>
									<CardCompact
										cardData={cardData}
										isVariantStoryPage={isVariantStoryPage}
										isVariantBulletin={isVariantBulletin}
										dataLayer={blockDataLayer}
									/>
								</div>
							);
						}
						return null;
					})
				)}
			</div>
			{!loading && cardsArray?.length > 4 && !showAll && (
				<div style={{ width: "100%", marginTop: "16px" }}>
					{isVariantStoryPage ? (
						<ButtonSeeMore
							type="storypage"
							textBtn={loadMoreLabel}
							iconPosition={"end"}
							iconName={"ArrowDown_2"}
							onClick={() => {
								handleDataLayerOnClick(blockDataLayer, position, pagePath);
								setShowAll(true);
							}}
						/>
					) : (
						<>
							<ButtonSeeMore
								type="storypage"
								textBtn={loadMoreLabel}
								onClick={() => {
									handleDataLayerOnClick(blockDataLayer, position, pagePath);
									setShowAll(true);
								}}
							/>
						</>
					)}
				</div>
			)}
		</>
	);
}
