type dataParams = {
	path?: string;
	value?: string[];
	[key: string]: any;
};

type dataResponse = {
	data?: any;
	error?: string;
};

export const fetchElasticData = async ({
	path = "",
	value = [],
	...otherParams
}: dataParams): Promise<dataResponse> => {
	const requestBody = { value, ...otherParams };
	const url = `${process.env.NEXT_PUBLIC_BASE_URL}api/${path}`;

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(requestBody),
			next: { revalidate: 120 },
		});

		if (!response.ok) {
			const error = `Error: ${response.status} ${response.statusText}`;
			return { error };
		}

		const data = await response.json();
		return { data };
	} catch (error) {
		console.error("Error en la consulta a Elastic:", error);
		return { error: "Error al obtener los datos de Elastic" };
	}
};
