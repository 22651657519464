import Skeleton from "react-loading-skeleton";

interface Props {
	isLoading: boolean;
	variant: "vertical" | "summary" | "button";
	quantity?: number;
}

const CardVerticalSkeleton = ({
	isLoading = true,
	quantity = 1,
	variant = "vertical",
}: Props) => {
	const renderSkeletons = () => {
		const skeletons = [];
		for (let i = 0; i < quantity; i++) {
			let content;

			switch (variant) {
				case "vertical":
					content = (
						<div
							style={{
								padding: "0",
								marginBottom: i < quantity - 1 ? "8px" : 0,
								display: "flex",
								alignItems: "center",
							}}
							key={i}
						>
							<Skeleton
								width={96}
								height={100}
								style={{ marginRight: "15px" }}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "20px",
								}}
							>
								<Skeleton
									width={100}
									height={10}
									style={{ marginTop: "18px" }}
								/>
								<div style={{ marginBottom: "5px" }}>
									<Skeleton width={260} height={10} count={3} />
								</div>
							</div>
						</div>
					);
					break;

				case "summary":
					content = (
						<div
							key={i}
							style={{
								display: "flex",
								flexDirection: "column",
								margin: i < quantity - 1 ? "auto auto 10px" : "auto",
								boxSizing: "border-box",
								width: "100%",
								borderBottom: "1px solid #e3e7eb",
								borderTop: "1px solid #e3e7eb",
								gap: "8px 0",
								background: "white",
								maxWidth: "1148px",
								padding: "16px",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "10px",
								}}
							>
								<Skeleton
									circle
									height={40}
									width={40}
									style={{ marginRight: "10px" }}
								/>
								<Skeleton height={20} width={240} />
							</div>
							<Skeleton height={300} />
							<Skeleton count={3} height={20} style={{ marginTop: "10px" }} />
						</div>
					);
					break;

				case "button":
					content = (
						<div
							style={{
								padding: "0",
								marginTop: "14px",
								width: "100%",
							}}
							key={i}
						>
							<Skeleton height={45} style={{ width: "100%" }} />
						</div>
					);
					break;

				default:
					content = <Skeleton width={96} height={100} key={i} />;
			}

			skeletons.push(content);
		}
		return skeletons;
	};

	return isLoading && renderSkeletons();
};

export default CardVerticalSkeleton;
